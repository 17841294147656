import { graphql } from "gatsby";
import React from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { HubspotWebform, SectionWrapper } from "../components/sections";
import PortableText from "../components/portableText";
import { ImageBuilder } from "../components/global/image-builder";

const TEASER_IMAGE_HEIGHT = 170;

export const query = graphql`
  query AssetLandingTemplateQuery($id: String!) {
    doc: sanityAsset(id: { eq: $id }) {
      _type
      _id
      status
      title
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImage
      }
      slug {
        current
      }
      formId
      _rawBody(resolveReferences: { maxDepth: 12 })
    }
  }
`;

const AssetLandingPageTemplate = ({ data }) => (
  <LayoutContainer doc={data.doc} hideNav={true}>
    <PageHeadMeta doc={data.doc} />
    {data.doc && <AssetLandingPage {...data.doc} />}
  </LayoutContainer>
);

export default AssetLandingPageTemplate;

const AssetLandingPage = ({ title, formId, _rawBody, mainImage }) => (
  <SectionWrapper>
    <div className="grid lg:grid-cols-2/3-1/3 gap-12 lg:gap-24 mt-12 mb-24">
      <div>
        <h1 className="text-2xl md:text-5xl font-bold tracking-tight mb-4">
          {title}
        </h1>
        {mainImage && (
          <ImageBuilder
            image={mainImage}
            height={TEASER_IMAGE_HEIGHT}
            alt={title}
            className="mb-4"
          />
        )}
        <PortableText blocks={_rawBody} className="mb-12" />
      </div>
      <div className="mt-8 w-full max-w-screen-sm ml-auto mr-auto">
        <HubspotWebform section={{ formId }} />
      </div>
    </div>
  </SectionWrapper>
);
